<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('teareqinven.requestinvgroup')}}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/teacher/teacherhome") {{ $t('teareqinven.teadashboard')}}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('teareqinven.requestinv')}}

                div.row.mt-5.pb-5.d-flex.align-items-center
                    div.col-lg-6.col-md-6.layout-spacing.mx-auto
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                            h4 {{ $t('teareqinven.completecode')}}
                                vue-form(:state="formstate")
                                    div.widget-content.widget-content-area

                                        div.form-group.mb-4
                                            label(for="sParentCenter") {{ $t('teareqinven.groupreqinv')}}
                                            select.form-control(id="sParentCenter" v-model="model.idGroup")
                                                option(value="0" selected) {{ $t('teareqinven.select')}}
                                                option(:value=" group.idGroup" v-for="group in groupsList") {{ group.sGroupName }}

                                        div.form-group.mb-4
                                            label(for="RequestNumber") {{ $t('teareqinven.required')}}
                                            validate(tag="div")
                                                input.form-control(id="RequestNumber" required :min="1" name="RequestNumber" type="number" placeholder="Numero de códigos Solicitados" v-model="model.RequestNumber")
                                                field-messages.text-danger(name="RequestNumber" )
                                                    div(slot="required") {{ $t('teareqinven.numcodreq')}}
                                                    div(slot="min") {{ $t('teareqinven.numcero')}}

                                        div.form-group.mb-4
                                            label(for="sRequestComments") {{ $t('teareqinven.comments')}}
                                            input.form-control(id="sRequestComments" name="sRequestComments" type="text" :placeholder="$t('teareqinven.comments')" v-model="model.sRequestComments")

                                        button.btn.btn-secondary(type="button" @click="Save") {{ $t('btns.btnsend')}}

</template>
<script>
/* eslint-disable */
export default {
    name: 'TeacherRequestInventory',
    data() {
        return {
            formstate: {},
            groupsList: [],
            model: {
                idGroup: 0,
                RequestNumber: 0,
                sRequestComments: ''
            },
        }
    },
    methods: {
        async getGroupList() {
            await axios.get(apiURL + 'Group/getTeacherGroups?idTeacher=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.groupsList = response.data.groupsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        
        async Save() {
            if(this.formstate.$invalid) {
                return;
            }

            this.openLoading('Guardando Datos ...');
            await axios.post(apiURL + 'Group/RequestInventoryCodes', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                router.push('/teacher/teacherhome')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
    },
    mounted() {
            this.getGroupList()
        }    
        
}
</script>